import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT } from '../date-formatting.js'

export const getUsers = () => {
	return configApi.get('/CSPortal/api/users').then(({ data: { users, ssoEnabled, ssoBypassDomains } }) => {
		return {
			ssoEnabled: ssoEnabled,
			ssoBypassDomains: ssoBypassDomains,
			data: users.map(user => ({
				userId: user.csPortalUserId,
				username: user.username,
				email: user.emailAddress,
				createdDate: format(new Date(user.createdDate), DATE_FORMAT),
				roleName: user.permissionRoleName,
				isActive: !user.isArchived
			}))
		}
	})
}

export const getUser = userId => {
	return configApi.get(`/CSPortal/api/users/${userId}`).then(({ data }) => ({
		data: {
			userId: data.csPortalUserId,
			username: data.username,
			forename: data.forename,
			surname: data.surname,
			email: data.emailAddress,
			permissionGroup: data.permissionRoleName,
			isActive: !data.isArchived,
			brandIds: data.brands?.map(({ brandId }) => brandId),
			roleId: data.permissionRoleId,
			auditHistory: data.loggedActions?.map(action => (
				{
					auditId: action.logActionId,
					dateTime: format(new Date(action.logDate), DATE_FORMAT),
					action: action.logAction,
					details: action.logActionDetails
				}
			))
		}
	}))
}

export const postUser = user => {
	return configApi.post('/CSPortal/api/users', {
		username: user.username,
		forename: user.forename,
		surname: user.surname,
		emailAddress: user.email,
		associatedBrandIDs: user.brandIds.length >= 1 ? user.brandIds : [0],
		productRoleId: user.roleId
	})
}

export const putUser = user => {
	return configApi.put('/CSPortal/api/users', {
		username: user.username,
		forename: user.forename,
		surname: user.surname,
		csPortalUserId: user.userId,
		emailAddress: user.email,
		associatedBrandIDs: user.brandIds,
		productRoleId: user.roleId
	})
}

export const deleteUser = userId => {
	return configApi.delete(`CSPortal/api/users/${userId}`)
}
