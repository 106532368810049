<template>
	<ViewLayout>
		<template #header-title>
			CS Portal User Management
		</template>
		<template #header-caption>
			Add, edit and manage CS Portal Users
		</template>
		<template #content>
			<slot name="content" />
		</template>
		<template #footer>
			<slot name="footer" />
		</template>
	</ViewLayout>
</template>
<script>
import ViewLayout from '../../layouts/view-layout.vue'
export default {
	components: { ViewLayout }
}
</script>
