<template>
	<UserManagementLayout>
		<template #content>
			<div class="d-flex align-center mb-4">
				<SecondaryActionButton
					:input-value="showFilters"
					class="mr-4"
					@click="showFilters = !showFilters"
				>
					<v-icon>
						mdi-filter
					</v-icon>
					Filter
				</SecondaryActionButton>
				<template v-if="showFilters">
					<TextField
						v-model="searchQuery"
						label="Username"
						append-icon="mdi-magnify"
						class="mr-4"
						style="flex: none;"
					/>
				</template>
				<v-spacer />
				<PrimaryActionButton
					v-if="canCreateAndEdit"
					@click="onCreateClick"
				>
					<v-icon
						left
						dark
					>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</div>
			<SectionCard>
				<template #title>
					CS Portal Users
				</template>
				<template #body>
					<DataTable
						:items="filteredUsers"
						:headers="tableHeaders"
						sort-by="userId"
						sort-desc
						@click:row="onEditClick"
					>
						<template #item.action="{ item }">
							<IconButton
								:tooltip-text="canCreateAndEdit ? 'Edit User' : 'View User'"
								@click="onEditClick(item)"
							>
								{{ canCreateAndEdit ? 'mdi-pencil' : 'mdi-eye' }}
							</IconButton>
							<IconButton
								v-if="canDelete"
								tooltip-text="Delete User"
								@click.stop.prevent="onRemoveClick(item)"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<Modal
				v-if="userIdToBeRemoved"
				width="500px"
			>
				<template #modal-title>
					Delete {{ userNameToBeRemoved }}
				</template>
				<template #modal-content>
					Are you sure you want to delete this User? <br>
					You will not be able to recover it if you confirm its deletion.
				</template>
				<template #modal-footer>
					<SecondaryActionButton @click="userIdToBeRemoved = null">
						Close
					</SecondaryActionButton>
					<DeleteButton @click="deleteUser">
						Delete
					</DeleteButton>
				</template>
			</Modal>
		</template>
	</UserManagementLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import TextField from '../text-field.vue'
import SecondaryActionButton from '../secondary-action-button.vue'
import PrimaryActionButton from '../primary-action-button.vue'
import IconButton from '../icon-button.vue'
import DeleteButton from '../delete-button.vue'
import DataTable from '../data-table.vue'
import { getUsers, deleteUser } from '../../utils/api/users.js'
import UserManagementLayout from './user-management-layout.vue'
import { USER_MANAGEMENT_CREATE_USER, USER_MANAGEMENT_EDIT_USER } from '../../router/route-names.js'
import { CAN_CREATE_AND_EDIT_CS_PORTAL_USERS, CAN_DELETE_CS_PORTAL_USERS } from '../../permissions/admin-portal-permissions.js'
import { CREATE_UPDATE_CSPORTAL_USERS } from '../../permissions/cs-portal-permissions.js'
import { showSnackbar } from '../../state/snackbar.js'
import SectionCard from '../section-card.vue'
import Modal from '../modal.vue'

export default {
	components: {
		SectionCard,
		UserManagementLayout,
		DataTable,
		PrimaryActionButton,
		SecondaryActionButton,
		DeleteButton,
		TextField,
		Modal,
		IconButton
	},
	props: {
		productID: Number
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			showFilters: false,
			users: [],
			searchQuery: '',
			userIdToBeRemoved: null,
			ssoEnabled: false,
			ssoBypassDomains: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'hasPermission']),
		canCreateAndEdit () {
			if (this.productID === 1) { return this.productAreaPermission(CAN_CREATE_AND_EDIT_CS_PORTAL_USERS) } else return this.hasPermission(CREATE_UPDATE_CSPORTAL_USERS)
		},
		canDelete () {
			if (this.productID === 1) { return this.productAreaPermission(CAN_DELETE_CS_PORTAL_USERS) } else return this.hasPermission(CREATE_UPDATE_CSPORTAL_USERS)
		},
		tableHeaders () {
			return [
				{
					value: 'userId',
					text: 'ID',
					width: '10%'
				},
				{
					value: 'username',
					text: 'Username',
					width: '20%'
				},
				{
					value: 'email',
					text: 'Email Address',
					width: '20%'
				},
				{
					value: 'roleName',
					text: 'Permission Group',
					width: '20%'
				},
				{
					value: 'createdDate',
					text: 'Created Date',
					width: '20%'
				},
				{
					value: 'action',
					text: 'Action',
					width: '10%',
					sortable: false
				}
			]
		},
		filteredUsers () {
			return this.users.filter(({ username }) => {
				return !this.searchQuery || username.toLowerCase().includes(this.searchQuery.toLowerCase())
			})
		},
		userNameToBeRemoved () {
			return this.users.find(({ userId }) => userId === this.userIdToBeRemoved)?.username
		}
	},
	async created () {
		this.getUsers()
	},
	methods: {
		async getUsers () {
			const { data: users, ssoEnabled, ssoBypassDomains } = await getUsers()
			this.users = users
			this.ssoEnabled = ssoEnabled
			this.ssoBypassDomains = ssoBypassDomains
		},
		onCreateClick () {
			this.$router.push({
				name: USER_MANAGEMENT_CREATE_USER
			})
		},
		onEditClick ({ userId }) {
			this.$router.push({
				name: USER_MANAGEMENT_EDIT_USER,
				query: {
					userId,
					ssoEnabled: this.ssoEnabled,
					ssoBypassDomains: this.ssoBypassDomains
				}
			})
		},
		async onRemoveClick ({ userId }) {
			this.userIdToBeRemoved = userId
		},
		async deleteUser () {
			await deleteUser(this.userIdToBeRemoved)
			this.getUsers()
			this.userIdToBeRemoved = null
			this.showSnackbar('This user has been deleted')
		}
	}
}
</script>
