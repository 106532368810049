export const SEE_DATA_SUBJECT_MANAGEMENT_AREA = 10
export const DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS = 11
export const SEE_DATA_SUBJECT_CURRENT_PREFERENCES = 12
export const EDIT_DATA_SUBJECT_CURRENT_PREFERENCES = 13
export const SEE_DATA_SUBJECT_CONTACT_DETAILS = 14
export const EDIT_DATA_SUBJECT_CONTACT_DETAILS = 15
export const USE_CREATE_DATA_SUBJECTS_AREA = 16
export const SEE_MANAGE_CSPORTAL_USERS_AREA = 17
export const CREATE_UPDATE_CSPORTAL_USERS = 18
export const SEE_DATA_SUBJECT_PREFERENCE_HISTORY = 24
export const SEE_DATA_SUBJECT_SUBMISSION_STRING = 51
export const SEE_DATA_SUBJECT_SUBMISSION_HISTORY = 69
export const SEE_DATA_SUBJECTS_WITH_NO_PREFERENCES = 109

export const CS_PORTAL_DATA_SUBJECTS_CHILD_SWITCHES = [
	SEE_DATA_SUBJECT_CURRENT_PREFERENCES,
	EDIT_DATA_SUBJECT_CURRENT_PREFERENCES,
	SEE_DATA_SUBJECT_CONTACT_DETAILS,
	EDIT_DATA_SUBJECT_CONTACT_DETAILS,
	SEE_DATA_SUBJECT_PREFERENCE_HISTORY,
	SEE_DATA_SUBJECT_SUBMISSION_HISTORY,
	SEE_DATA_SUBJECT_SUBMISSION_STRING,
	SEE_DATA_SUBJECTS_WITH_NO_PREFERENCES
]
export const SEE_DATA_SUBJECT_PREFERENCE_HISTORY_CHILD_SWITCHES = [
	SEE_DATA_SUBJECT_SUBMISSION_STRING
]

export const permissionLabels = {
	[SEE_DATA_SUBJECT_MANAGEMENT_AREA]: 'Can View Data Subjects',
	[DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS]: 'Data Subjects - Full Permissions',
	[SEE_DATA_SUBJECT_CURRENT_PREFERENCES]: 'Can View Preferences',
	[EDIT_DATA_SUBJECT_CURRENT_PREFERENCES]: 'Can Edit Preferences',
	[SEE_DATA_SUBJECT_CONTACT_DETAILS]: 'Can View Contact Details',
	[EDIT_DATA_SUBJECT_CONTACT_DETAILS]: 'Can Edit Contact Details',
	[SEE_DATA_SUBJECT_PREFERENCE_HISTORY]: 'Can View Data Subject Audit History',
	[SEE_DATA_SUBJECT_SUBMISSION_HISTORY]: 'Can View Data Subject Submission History',
	[USE_CREATE_DATA_SUBJECTS_AREA]: 'Can Create Data Subjects',
	[SEE_MANAGE_CSPORTAL_USERS_AREA]: 'Can View CS Portal Users',
	[CREATE_UPDATE_CSPORTAL_USERS]: 'Can Create & Edit CS Portal Users',
	[SEE_DATA_SUBJECT_SUBMISSION_STRING]: 'Can View Data Subject Submission String',
	[SEE_DATA_SUBJECTS_WITH_NO_PREFERENCES]: 'Can Search for Data Subjects Without Preferences'
}
