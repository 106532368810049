<template>
	<v-btn
		v-bind="$props"
		color="white--text red"
		class="px-10"
		v-on="$listeners"
	>
		<slot />
	</v-btn>
</template>

<script>
import { VBtn } from 'vuetify/lib'

export default {
	name: 'delete-button',
	extends: VBtn
}
</script>
